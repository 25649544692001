@font-face {
font-family: 'avenir';
src: url(/_next/static/media/c4804c8b393ed559-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: 'avenir Fallback';src: local("Arial");ascent-override: 97.16%;descent-override: 35.56%;line-gap-override: 0.00%;size-adjust: 102.92%
}.__className_d72a2a {font-family: 'avenir', 'avenir Fallback';font-weight: 500;font-style: normal
}.__variable_d72a2a {--font-avenir: 'avenir', 'avenir Fallback'
}

@font-face {
font-family: 'monunumentRegular';
src: url(/_next/static/media/81fbe56a9debec2d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: 'monunumentRegular Fallback';src: local("Arial");ascent-override: 47.54%;descent-override: 16.58%;line-gap-override: 5.53%;size-adjust: 180.89%
}.__className_71fee8 {font-family: 'monunumentRegular', 'monunumentRegular Fallback';font-weight: 500;font-style: normal
}.__variable_71fee8 {--font-monument-regular: 'monunumentRegular', 'monunumentRegular Fallback'
}

@font-face {
font-family: 'monumentLight';
src: url(/_next/static/media/1ee32a78e8f3452b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: 'monumentLight Fallback';src: local("Arial");ascent-override: 49.97%;descent-override: 17.43%;line-gap-override: 5.81%;size-adjust: 172.09%
}.__className_2c2ddc {font-family: 'monumentLight', 'monumentLight Fallback';font-weight: 500;font-style: normal
}.__variable_2c2ddc {--font-monument-light: 'monumentLight', 'monumentLight Fallback'
}

@font-face {
font-family: 'monunmentBlack';
src: url(/_next/static/media/d8484bd656ff38d0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: 'monunmentBlack Fallback';src: local("Arial");ascent-override: 48.17%;descent-override: 16.80%;line-gap-override: 5.60%;size-adjust: 178.54%
}.__className_a99bbb {font-family: 'monunmentBlack', 'monunmentBlack Fallback';font-weight: 500;font-style: normal
}.__variable_a99bbb {--font-monument-black: 'monunmentBlack', 'monunmentBlack Fallback'
}

@font-face {
font-family: 'monumentUltraBold';
src: url(/_next/static/media/d8484bd656ff38d0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: 'monumentUltraBold Fallback';src: local("Arial");ascent-override: 48.17%;descent-override: 16.80%;line-gap-override: 5.60%;size-adjust: 178.54%
}.__className_669171 {font-family: 'monumentUltraBold', 'monumentUltraBold Fallback';font-weight: 500;font-style: normal
}.__variable_669171 {--font-monument-ultrabold: 'monumentUltraBold', 'monumentUltraBold Fallback'
}

